















































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Fragment } from 'vue-fragment';
import {
  convertAPIToDateFormat,
  convertDateFieldToAPIFormatWithoutTime
} from '@/utils/date.util';
import { DispatchModule, DivisionModule } from '@/store/index';
import { DispatchService } from '@/shared/services/dispatch/dispatch.service';
import { BookingType } from '../../models/filters/booking-filter.model';
import { DateType } from '../../models/filters/date-filter.model';
import { DispatchType } from '../../models/dispatch.model';
import { ToastHelper } from '@/utils/toast.util';
import {
  FilterInput,
  FilterConditions,
  TransformFiltersToJson
} from '@/shared/services/filter/filter.service';
import {
  BS_DATEPICKER_FORMAT,
  convertDateArrayFormatFieldToAPIFormat,
  DATE_API_FORMAT
} from '@/utils/date.util';
import Loading from '@/shared/components/Loading.vue';

import moment from 'moment';

@Component({
  components: {
    Fragment,
    Loading
  }
})
export default class BookingFilters extends Vue {
  @Prop() isCompleteActive: boolean;

  routeName = 'export';
  loading = false;
  filterField = 'cutOff';
  to = moment().format(BS_DATEPICKER_FORMAT);
  from = moment()
    .subtract(12, 'days')
    .format(BS_DATEPICKER_FORMAT);
  convertDateFieldToAPIFormatWithoutTime = convertDateFieldToAPIFormatWithoutTime;
  maxDate = null;
  statusOptions = [
    { value: null, text: 'All' },
    { value: true, text: 'Active' },
    { value: false, text: 'Inactive' }
  ];
  bookDateOptions = [
    { value: 'erd', text: 'ERD' },
    { value: 'cutOff', text: 'C/O ' }
  ];

  BookingNumberFilter = new FilterInput({
    condition: FilterConditions.Include,
    search: null,
    field: BookingType.BookingNumber
  });

  StatusFilter = new FilterInput({
    condition: FilterConditions.Equal,
    search: true,
    field: BookingType.Status
  });

  fromFilter = new FilterInput({
    condition: FilterConditions.GreaterOrEqual,
    search: moment()
      .subtract(12, 'days')
      .format(BS_DATEPICKER_FORMAT),
    field: 'cutOff'
  });

  toFilter = new FilterInput({
    condition: FilterConditions.LessThenOrEqual,
    search: moment().format(BS_DATEPICKER_FORMAT),
    field: 'cutOff'
  });

  divFilter = new FilterInput({
    condition: FilterConditions.Equal,
    search: DivisionModule.division,
    field: 'division'
  });

  @Watch('fromFilter')
  onFromFilterChange() {
    this.from = convertAPIToDateFormat(this.fromFilter.search);
  }
  @Watch('toFilter')
  onToFilterChange() {
    this.to = convertAPIToDateFormat(this.toFilter.search);
  }

  created() {
    this.search();
  }

  fieldChange() {
    this.filterField === 'date'
      ? (this.maxDate = moment().format())
      : (this.maxDate = null);
    this.toFilter.field = this.filterField;
    this.fromFilter.field = this.filterField;
  }

  async search() {
    this.loading = true;

    try {
      this.fromFilter.search = convertDateArrayFormatFieldToAPIFormat(
        this.fromFilter.search,
        [BS_DATEPICKER_FORMAT, DATE_API_FORMAT]
      );

      this.toFilter.search = moment(this.toFilter.search, [
        BS_DATEPICKER_FORMAT,
        DATE_API_FORMAT
      ])
        .endOf('day')
        .format(DATE_API_FORMAT);

      DispatchModule.setFilters(
        TransformFiltersToJson([
          this.toFilter,
          this.fromFilter,
          this.StatusFilter,
          this.BookingNumberFilter,
          this.divFilter
        ])
      );

      await DispatchModule.search({
        dispatchType: DispatchType[this.routeName.substr(0, 3).toUpperCase()],
        category: this.$route.name.replace(`${this.routeName}-`, '')
      });
    } catch (err) {
      ToastHelper.show(
        `${this.routeName}/${this.$route.name.replace(
          `${this.routeName}-`,
          ''
        )}`,
        err.message,
        5000,
        'danger'
      );
    }

    DispatchModule.dispatches.forEach(dispatch => {
      dispatch.ck = false;
    });

    this.loading = false;
  }

  async complete() {
    this.loading = true;

    try {
      const bookingNumbersArr = DispatchModule.dispatches
        .filter(dispatch => dispatch.ck)
        .map(dispatch => dispatch.id);
      await DispatchService.completeBookings(bookingNumbersArr);

      ToastHelper.show(
        `${this.routeName}/${this.$route.name.replace(
          `${this.routeName}-`,
          ''
        )}`,
        'Complete successful',
        5000,
        'success'
      );
    } catch (err) {
      ToastHelper.show(
        `${this.routeName}/${this.$route.name.replace(
          `${this.routeName}-`,
          ''
        )}`,
        err.message,
        5000,
        'danger'
      );
    }

    this.loading = false;
  }

  resetFilters() {
    this.BookingNumberFilter = new FilterInput({
      condition: FilterConditions.Include,
      search: null,
      field: BookingType.BookingNumber
    });

    this.StatusFilter = new FilterInput({
      condition: FilterConditions.Equal,
      search: null,
      field: BookingType.Status
    });

    this.fromFilter = new FilterInput({
      condition: FilterConditions.GreaterOrEqual,
      search: moment()
        .subtract(12, 'days')
        .format(BS_DATEPICKER_FORMAT),
      field: 'cutOff'
    });

    this.toFilter = new FilterInput({
      condition: FilterConditions.LessThenOrEqual,
      search: moment().format(BS_DATEPICKER_FORMAT),
      field: 'cutOff'
    });
    this.filterField = 'cutOff';

    DispatchModule.setFilters(
      TransformFiltersToJson([
        this.toFilter,
        this.fromFilter,
        this.StatusFilter,
        this.BookingNumberFilter,
        this.divFilter
      ])
    );
  }
}
