import {
  CellContentType,
  TableCategory,
  TableHeaders
} from '@/shared/components/table/models';
import moment from 'moment';
import { DATE_API_FORMAT } from '@/utils/date.util';

function getDay(schedule) {
  return schedule
    ? moment(schedule, DATE_API_FORMAT).format('MM/DD/YY')
    : schedule;
}

export const Categories1TableHeaders: TableHeaders[] = [
  // { key: 'number', label: 'NO' },
  {
    key: 'ck',
    label: 'CK',
    content: {
      type: CellContentType.Checkbox
    }
  },
  {
    key: 'id',
    label: 'Booking'
  },
  {
    key: 'shippingLine',
    label: 'SSL'
  },
  {
    key: 'billTo',
    label: 'Bill To'
  },
  {
    key: 'erd',
    label: 'ERD',
    formatter: getDay
  },
  {
    key: 'cutOff',
    label: 'C/O',
    formatter: getDay
  }
];

export const Container1Headers: TableHeaders[] = [
  {
    key: 'containerSize1',
    label: '1 CONT SIZE'
  },
  {
    key: 'containerType1',
    rendererKey: 'cntr1',
    label: '1 CONT TYPE',
    collapsed: false
  },
  {
    key: 'containerTotal1',
    rendererKey: 'cntr1',
    label: '1 CONT TOTAL',
    collapsed: false
  },
  {
    key: 'containerUsed1',
    rendererKey: 'cntr1',
    label: '1 CONT USED',
    collapsed: false
  },
  {
    key: 'containerBalance1',
    rendererKey: 'cntr1',
    label: '1 CONT BALANCE',
    collapsed: false
  }
];

export const Container2Headers: TableHeaders[] = [
  {
    key: 'containerSize2',
    label: '2 CONT SIZE'
  },
  {
    key: 'containerType2',
    rendererKey: 'cntr2',
    label: '2 CONT TYPE',
    collapsed: false
  },
  {
    key: 'containerTotal2',
    rendererKey: 'cntr2',
    label: '2 CONT TOTAL',
    collapsed: false
  },
  {
    key: 'containerUsed2',
    rendererKey: 'cntr2',
    label: '2 CONT USED',
    collapsed: false
  },
  {
    key: 'containerBalance2',
    rendererKey: 'cntr2',
    label: '2 CONT BALANCE',
    collapsed: false
  }
];

export const Container3Headers: TableHeaders[] = [
  {
    key: 'containerSize3',
    label: '3 CONT SIZE'
  },
  {
    key: 'containerType3',
    rendererKey: 'cntr3',
    label: '3 CONT TYPE',
    collapsed: false
  },
  {
    key: 'containerTotal3',
    rendererKey: 'cntr3',
    label: '3 CONT TOTAL',
    collapsed: false
  },
  {
    key: 'containerUsed3',
    rendererKey: 'cntr3',
    label: '3 CONT USED',
    collapsed: false
  },
  {
    key: 'containerBalance3',
    rendererKey: 'cntr3',
    label: '3 CONT BALANCE',
    collapsed: false
  }
];

export const BookingCategoriesHeaders: TableCategory[] = [
  {
    key: 'category1',
    label: ' ',
    maxColspan: Categories1TableHeaders.length,
    minColspan: 1
  },
  {
    key: 'cntr1',
    label: '1 CNTR',
    maxColspan: Container1Headers.length,
    minColspan: 1,
    class: 'info-color',
    collapsed: false,
    opportunityСhange: true
  },
  {
    key: 'cntr2',
    label: '2 CNTR',
    maxColspan: Container2Headers.length,
    minColspan: 1,
    class: 'equipment-color',
    collapsed: false,
    opportunityСhange: true
  },
  {
    key: 'cntr3',
    label: '3 CNTR',
    maxColspan: Container3Headers.length,
    minColspan: 1,
    class: 'p-out-color',
    collapsed: false,
    opportunityСhange: true
  }
];

export const BookingTableHeaders: TableHeaders[] = [
  ...Categories1TableHeaders,
  ...Container1Headers,
  ...Container2Headers,
  ...Container3Headers
];
