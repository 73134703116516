






























































































































































































































































































































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { DispatchService } from '@/shared/services/dispatch/dispatch.service';
import { CompanyService } from '@/shared/services/mater-data/company.service';
import { FileUploadService } from '@/shared/services/fileUpload.service';
import { DispatchType } from '../../models/dispatch.model';
import AskModal from '@/shared/components/modal/AskModal.vue';
import {
  convertDateFieldToAPIFormat,
  convertAPIToFormat,
  BS_DATEPICKER_FORMAT,
  convertAPIToDateFormat,
  DATE_API_FORMAT
} from '@/utils/date.util';
import { validationMixin } from 'vuelidate';
import { bookingValidation, bookingData } from '../models/booking.model';
import { ToastHelper } from '@/utils/toast.util';
import { containerSize, containerType, shippingLine } from '@/shared/mocks/wo';

import FileDownload from '@/shared/components/form/FileDownload.vue';
import Loading from '@/shared/components/Loading.vue';
import moment from 'moment';
import { DivisionModule } from '@/store/index';
import { deepClone } from '@/utils/utils';
import { WorkOrderService } from '@/shared/services/wo/wo-import.service';

Component.registerHooks(['validations']);

@Component({
  components: {
    FileDownload,
    Loading,
    AskModal
  },
  mixins: [validationMixin]
})
export default class BookingModal extends Vue {
  @Prop({ default: null }) bookingId: any;

  importedFiles = [];
  loading = false;
  isOpenedAskModal = false;
  convertDateFieldToAPIFormat = convertDateFieldToAPIFormat;
  dispatchService = DispatchService;
  companyService = CompanyService;
  fileUploadService = FileUploadService;
  saveClicked = false;
  containerType = null;
  containerSize = null;
  shippingLine = null;
  booking = null;
  form: any = this.booking;
  datesToConvert = ['erd', 'cutOff', 'createdDate', 'updatedDate'];
  today = moment().format(BS_DATEPICKER_FORMAT);
  todayApiformat = moment().format(DATE_API_FORMAT);
  from: any;

  created() {
    this.getSelectValues();
    if (this.bookingId) {
      this.changeBooking();
    } else {
      this.booking = JSON.parse(JSON.stringify(bookingData));
      this.form = this.booking;
    }
  }

  async getSelectValues() {
    const { containerType, containerSize, chassisPool, shippingLine } =
      await WorkOrderService.getSelectValues();
    this.containerType = containerType;
    this.containerSize = containerSize;
    this.shippingLine = shippingLine;
  }

  get maxErd() {
    const cutOff = Number(this.form?.cutOff);

    if (isNaN(cutOff)) {
      return null;
    } else {
      return convertAPIToDateFormat(this.form?.cutOff);
    }
  }

  get minCutOff() {
    const erd = Number(this.form?.erd);

    if (isNaN(erd)) {
      return null;
    } else {
      return convertAPIToDateFormat(this.form?.erd);
    }
  }

  async changeBooking() {
    const res = await this.dispatchService.getBookingById(this.bookingId);

    while (res.data.containers.length < 3) {
      res.data.containers.push(bookingData.containers[0]);
    }

    this.datesToConvert.forEach(date => {
      if (res.data[date]) {
        res.data[date] = convertAPIToFormat(
          res.data[date],
          BS_DATEPICKER_FORMAT
        );
      }
    });

    if (res.data.files.length === 0) {
      res.data.files[0] = { ...bookingData.files[0] };
    }

    this.importedFiles = JSON.parse(JSON.stringify(res.data.files));

    this.booking = JSON.parse(JSON.stringify(res.data));
    console.log('[Starting]: ', this.booking);
    this.form = this.booking;
  }

  validations() {
    return {
      form: bookingValidation
    };
  }

  validateState(val: any) {
    if (this.$v && this.$v.form && this.$v.form[val]) {
      const { $dirty, $error }: any = this.$v.form[val];
      return $dirty ? !$error : null;
    }
  }

  validateArrayState(item) {
    let action = null;

    if (item.$dirty) {
      const isNotEmpty = Object.values(
        this.$v.form.containers.$each.$iter
      ).some(value => value.$model.total && value.$model.size);

      if (isNotEmpty) {
        const { $dirty, $error } = item;
        action = $dirty ? !$error : null;
      } else {
        action = false;
      }
    }

    return action;
  }

  updatePullOut(data) {
    this.booking.pulloutLocation = data?.id;
    this.booking.pulloutLocationName = data?.name;
  }

  updateDelivery(data) {
    this.booking.deliveryLocation = data?.id;
    this.booking.deliveryLocationName = data?.name;
  }

  updateReturn(data) {
    this.$v.form.returnLocationName.$model = data.name;
    this.$v.form.returnLocation.$model = data.id;
  }

  updateBillTo(data) {
    this.$v.form.billToName.$model = data?.name;
    this.$v.form.billTo.$model = data?.id;
  }

  async onFileUpload(file, index) {
    if (!file) {
      if (
        !this.bookingId ||
        !this.importedFiles[index].filename ||
        !this.booking.files[index].filename
      ) {
        this.booking.files[index].documentName = null;
        this.booking.files[index].filename = null;
        this.booking.files[index].file = null;
        this.booking.files[index].tempS3FileName = '';
        this.booking.files[index].tempS3PreSignedUrl = '';
      } else {
        try {
          this.loading = true;

          await DispatchService.deleteFileById(
            this.bookingId,
            this.booking.files[index].category
          );

          this.booking.files[index].documentName = null;
          this.booking.files[index].filename = null;
          this.booking.files[index].file = null;
          this.booking.files[index].tempS3FileName = '';
          this.booking.files[index].tempS3PreSignedUrl = '';

          this.loading = false;

          ToastHelper.show(
            'Booking file delete',
            'File deletion successful',
            5000,
            'success'
          );
        } catch (err) {
          ToastHelper.show('Booking file delete', err.message, 5000, 'danger');
        }
      }
    } else {
      this.booking.files[index].filename = file.filename;
      this.booking.files[index].documentName = file.documentName;
      this.booking.files[index].file = file.file;
      this.booking.files[index].tempS3FileName = file.tempS3FileName;
      this.booking.files[index].tempS3PreSignedUrl = file.tempS3PreSignedUrl;
    }
  }

  async uploadFilesToDB(data) {
    const fileUploads = [];
    console.log(data.files[0]);

    data.files.forEach((file, index) => {
      console.log(file.file);
      if (file?.file) {
        fileUploads.push(
          this.fileUploadService.uploadFile(file.tempS3PreSignedUrl, file.file)
        );
      }
    });

    await Promise.all(fileUploads);
  }

  closeBooking() {
    this.$bvModal.hide('booking-modal');
  }

  async save() {
    try {
      this.saveClicked = true;

      if (this.isFormInValid() || this.validateContainers()) {
        return;
      }

      this.loading = true;

      const toSendData = deepClone(this.booking);

      toSendData.containers = toSendData.containers.filter(
        container => container.size && container.type && container.total
      );

      //BE ask to add division for saving new booking
      toSendData.division = DivisionModule.division;

      console.log('[save]: ', toSendData.files[0]);

      const toNumber = ['used', 'balance', 'total'];
      const toDeleteInFiles = [
        'createdBy',
        'createdDate',
        'updatedBy',
        'updatedDate',
        'body',
        'file',
        'id'
      ];
      const toDelete = [
        'createdBy',
        'createdDate',
        'updatedBy',
        'updatedDate',
        'date',
        'id'
      ];

      toSendData.files = toSendData.files.filter(file => !!file.tempS3FileName);
      toSendData.freeDays = parseInt(toSendData.freeDays);
      await this.uploadFilesToDB(toSendData);

      toSendData.containers.forEach((cont, index, arr) => {
        toNumber.forEach(number => {
          cont[number] = Number(cont[number]);
        });
      });

      this.datesToConvert.forEach(date => {
        if (toSendData[date]) {
          toSendData[date] = convertDateFieldToAPIFormat(
            toSendData[date],
            BS_DATEPICKER_FORMAT
          );
        }
      });

      toDeleteInFiles.forEach(item => {
        toSendData.files.forEach(file => {
          delete file[item];
        });
      });

      if (this.bookingId) {
        toDelete.forEach(item => {
          delete toSendData[item];
        });

        try {
          toSendData.files = toSendData.files.filter(
            file => !!file.tempS3FileName
          );
          toSendData.files.forEach((file, index, arr) => {
            delete toSendData.files[index].file;
          });
          await DispatchService.saveRequest(
            DispatchType[this.$route.name.substr(0, 3).toUpperCase()],
            this.$route.name.replace('export-', ''),
            this.bookingId,
            toSendData
          );

          ToastHelper.show(
            'Booking code update',
            'Update successful',
            5000,
            'success'
          );
        } catch (err) {
          ToastHelper.show(
            'Booking code update',
            err.response?.data?.error || err.message,
            5000,
            'danger'
          );
        }
      } else {
        try {
          await DispatchService.addRequest(
            DispatchType[this.$route.name.substr(0, 3).toUpperCase()],
            this.$route.name.replace('export-', ''),
            toSendData
          );

          ToastHelper.show(
            'Booking code create',
            'Create successful',
            5000,
            'success'
          );
        } catch (err) {
          ToastHelper.show(
            'Booking code create',
            err.response?.data?.error || err.message,
            5000,
            'danger'
          );
        }
      }

      console.log('[finished]: ', toSendData);

      this.closeBooking();
      this.loading = false;
      this.$emit('updateTable');
    } catch (e) {
      console.log(e);
    }
  }

  async complete() {
    this.loading = true;

    try {
      await DispatchService.completeBookings([this.booking.id]);

      this.closeBooking();

      ToastHelper.show(
        `${this.$route.name}/${this.$route.name.replace('export-', '')}`,
        'Complete successful',
        5000,
        'success'
      );
    } catch (err) {
      ToastHelper.show(
        `${this.$route.name}/${this.$route.name.replace('export-', '')}`,
        err.message,
        5000,
        'danger'
      );
    }

    this.loading = false;
  }

  async inComplete() {
    this.loading = true;

    try {
      await DispatchService.inCompleteBookings([this.booking.id]);

      this.closeBooking();

      ToastHelper.show(
        `${this.$route.name}/${this.$route.name.replace('export-', '')}`,
        'Incomplete successful',
        5000,
        'success'
      );
    } catch (err) {
      ToastHelper.show(
        `${this.$route.name}/${this.$route.name.replace('export-', '')}`,
        err.message,
        5000,
        'danger'
      );
    }

    this.loading = false;
  }

  remove() {
    this.isOpenedAskModal = true;
  }

  async confirmDeletion() {
    this.isOpenedAskModal = false;
    this.loading = true;

    try {
      await DispatchService.deleteBooking(this.booking.id);

      ToastHelper.show(
        `Booking# ${this.booking.id}`,
        'Deletion successful',
        5000,
        'success'
      );
    } catch (err) {
      ToastHelper.show(
        `Booking# ${this.booking.id}`,
        err.message,
        5000,
        'danger'
      );
    }

    this.closeBooking();
    this.loading = false;
    this.$emit('updateTable');
  }

  declineDeletion() {
    this.isOpenedAskModal = false;
  }

  isFormInValid() {
    this.$v.form.$touch();
    console.log(this.$v.form);
    return this.$v.form.$anyError;
  }

  validateContainers() {
    this.$v.form.containers.$each.$touch();
    const isNotEmpty = Object.values(this.$v.form.containers.$each.$iter).some(
      (container: any) =>
        container.$model.size && container.$model.type && container.$model.total
    );

    if (!isNotEmpty) {
      ToastHelper.show(
        'Booking',
        'At least one container must be completely filled',
        5000,
        'warning'
      );

      return true;
    }

    return false;
  }
}
